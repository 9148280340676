@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer,
  #info {
    width: 100%;
  }

  #portfolio {
    width: 110%;
  }
}
@media screen and (max-width: 321px) {
  #features {
    padding: 20px;
    width: 150%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer,
  #info {
    width: 100%;
  }

  #portfolio {
    width: 110%;
  }
}

@media screen and (max-width: 376px, min-width:321px) {
  
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  
}
@media screen and (max-width: 430px, min-width:377px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 110%;
  }
}
@media screen and (max-width: 767px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  

  #portfolio {
    width: 100%;
  }
}
